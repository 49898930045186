import React from "react";

import { Carousel } from "react-responsive-carousel";
import { SectionDivider } from "../section-divider/section-divider";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";

export const QuoteCarousel = ({
  quotes,
  withComma = false,
}: {
  quotes: Array<TypeHomepageV2Quote>;
  withComma?: boolean;
}) => {
  return (
    <Carousel
      showArrows={false}
      showIndicators={true}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      useKeyboardArrows={true}
      autoPlay={true}
      interval={5000}
    >
      {quotes.map((quote, index) => (
        <div className="carousel-slide" key={index}>
          <div className="layover" />
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 offset-lg-3 d-flex flex-column align-items-center">
                {quote.image && (
                  <img
                    src={quote.image.url}
                    alt={quote.image.alt}
                    style={{
                      filter: "grayscale(1)",
                      width: 210,
                      marginBottom: 24,
                    }}
                  />
                )}
                <p className="quote">"{quote.text}"</p>
                <SectionDivider />
                <div className="author">
                  <div className="name">
                    {quote.author_name}
                    {withComma ? "," : ""}
                  </div>
                  <div className="role">{quote.author_title}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};
