import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./agency-partner.scss";
import {
  getPage,
  TypeAgencyPartnerTeamPage,
  TypeAgencyPartnerQueryResult,
} from "../../utils/queries/agency_partner_team_up_w";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";

import HubspotForm from "../../components/hubspot-form";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import { QuoteCarousel } from "../../components/reusable/quote-carousel";

import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
  {
    allPrismicAgencyPartnerTeamUpW {
      edges {
        node {
          data {
            body {
              ... on PrismicAgencyPartnerTeamUpWBodyHubspotForm {
                id
                slice_type
                slice_label
                primary {
                  form_id
                  portal_id
                }
              }
            }
            download_image {
              alt
              copyright
              url
              thumbnails
            }
            download_text {
              html
              raw
              text
            }
            form_heading
            form_subheading
            hero_image {
              alt
              url
              thumbnails
              copyright
            }
            hero_subtitle
            hero_text {
              html
              raw
              text
            }
            page_meta_description
            page_meta_thumbnail {
              alt
              copyright
              thumbnails
              url
            }
            partners_group {
              logo {
                alt
                copyright
                thumbnails
                url
              }
              partners_title
              partnes_list_text {
                html
                raw
                text
              }
            }
            partners_headline
            partners_subheading {
              html
              raw
              text
            }
            quotes {
              author_name
              author_position
              quote
              logo {
                alt
                copyright
                thumbnails
                url
              }
            }
            title
            meta_title
          }
        }
      }
    }
  }
`;

const AgencyPartnerTeamPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeAgencyPartnerQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicAgencyPartnerTeamUpW",
    true
  ) as TypeAgencyPartnerTeamPage;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="agency-partner-team-up">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result.hero_text)}
          image={result.hero_image}
          subheader={RichTextRender(result.hero_subtitle)}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="benefits">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="title">
                  {RichTextRender(result.partners_headline)}
                </h2>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <h6>{RichTextRender(result.partners_subheading)}</h6>
              </div>
            </div>

            <div className="row">
              {result.partners_group.map((partner, index) => (
                <div className="col-12 col-lg-4 benefit" key={index}>
                  <img src={partner.logo.url} alt={partner.logo.alt} />
                  <div className="service-title pl-8 pr-8">
                    {partner.partners_title}
                  </div>
                  <div className="description">
                    {RichTextRender(partner.partnes_list_text)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="quotes">
          <div className="container">
            <div className="row">
              <div className="col-12 quote-carousel-section">
                <QuoteCarousel
                  withComma={true}
                  quotes={result.quotes.map(
                    (quote) =>
                      ({
                        image: quote.logo,
                        text: quote.quote,
                        author_name: quote.author_name,
                        author_title: quote.author_position,
                      } as TypeHomepageV2Quote)
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="form_section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="title">{RichTextRender(result.form_heading)}</h2>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <h6>{RichTextRender(result.form_subheading)}</h6>
              </div>
            </div>

            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 text-center">
                <HubspotForm
                  key="2912"
                  formId="2320702e-2e34-48d3-9259-ca8ba569f57d"
                  portalId="5096486"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticTypeAgencyPartnerTeamPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <AgencyPartnerTeamPage location={location} data={data} />
      )}
    />
  );
};
export default StaticTypeAgencyPartnerTeamPage;
