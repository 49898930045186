import {
  PrismicImage,
  PrismicPageDefinition,
  PrismicRichText,
  PrismicExternalLink,
} from "./types";
import { PrismicRawResourceData } from "./resources";
import { StringNullableChain } from "lodash";

export type TypeAgencyPartnerPartners = {
  logo: PrismicImage;
  partners_title: string;
  partnes_list_text: PrismicRichText;
};

export type TypeAgencyPartnerTeamPage = {
  // Hero
  title: string;
  meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
  hero_image: PrismicImage;
  hero_text: PrismicRichText;
  hero_subtitle: string;

  // Agency Partners
  partners_headline: string;
  partners_subheading: PrismicRichText;

  // Form
  form_heading: string;
  form_subheading: string;

  // Download CTA
  download_text: PrismicRichText;
  download_image: PrismicImage;

  partners_group: TypeAgencyPartnerPartners[];

  // Quotes
  quotes: Array<{
    author_name: string;
    author_position: string;
    quote: string;
    logo: PrismicImage;
  }>;
};

export type TypeAgencyPartnerQueryResult = {
  allPrismicAgencyPartnerTeamUpW: {
    edges: Array<{
      node: { data: TypeAgencyPartnerTeamPage };
    }>;
  };
};

export const getPage = (
  data: TypeAgencyPartnerQueryResult
): TypeAgencyPartnerTeamPage => {
  return data.allPrismicAgencyPartnerTeamUpW.edges[0].node.data;
};
